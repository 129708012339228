import { BottomSheetLoadingContent } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { useCreateShiftAccountLog } from "@src/appV2/Accounts/api/useCreateShiftAccountLog";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics/log";
import type { GeoLocation } from "@src/appV2/Location";
import {
  type UseIsInsideGeofenceResult,
  useLazyIsInsideGeofence,
} from "@src/appV2/Location/hooks/useIsInsideGeofence";
import { useState } from "react";

import { AcceptNonInstantPayContent } from "./AcceptNonInstantPayContent";
import { LocationPermissionsContent } from "./LocationPermissionsContent";
import { TooFarAwayPopup } from "./TooFarAwayPopup";

export interface WorkerGeofenceCheckProps {
  action: "clock-in" | "clock-out";
  shiftId: string;
  workerId: string;
  workplaceName: string;
  workplaceLocation: GeoLocation;
  onSuccess: () => void;
  onSkipLocationCheck: () => void;
  onCancel: () => void;
}

type WorkerGeofenceCheckStep =
  | "location-permissions"
  | "fetching-geofence-status"
  | "accept-non-instant-pay"
  | "too-far-away";

const WORKPLACE_DEFAULT_GEOFENCE_DISTANCE_IN_MILES = 0.75;

function getGeofenceCheckFailureReason(params: {
  action: "clock-in" | "clock-out";
  shiftId: string;
  workplaceName: string;
  checkProviders?: UseIsInsideGeofenceResult["checkProviders"];
  isError?: boolean;
}) {
  const { action, shiftId, workplaceName, checkProviders, isError } = params;

  if (isError) {
    return `Failed to ${action} to a shift id: ${shiftId} from facility ${workplaceName}
            because there was an error checking the user's location. ${checkProviders?.join(", ")}`;
  }

  return `Failed to ${action} to a shift id: ${shiftId} from facility ${workplaceName}
            because user is not inside the Facility's geofence. ${checkProviders?.join(", ")}`;
}

export function WorkerGeofenceCheck(props: WorkerGeofenceCheckProps) {
  const {
    shiftId,
    workerId,
    workplaceName,
    workplaceLocation,
    action,
    onSuccess,
    onSkipLocationCheck,
    onCancel,
  } = props;

  const [content, setContent] = useState<WorkerGeofenceCheckStep>("location-permissions");

  const getIsInsideGeofence = useLazyIsInsideGeofence({
    requestUniqueId: `${shiftId}-${workerId}`,
    action,
    fallbackGeofenceDistanceInMiles: WORKPLACE_DEFAULT_GEOFENCE_DISTANCE_IN_MILES,
    fallbackGeofenceLocation: workplaceLocation,
  });
  const { mutate: createShiftAccountLog } = useCreateShiftAccountLog();

  async function resolveGeofenceStatus() {
    try {
      const { data } = await getIsInsideGeofence();

      if (data?.isInsideGeofence) {
        onSuccess();
      } else {
        setTooFarAwayContent(data);
      }
    } catch {
      setTooFarAwayContent();
    }
  }

  function setTooFarAwayContent(result?: UseIsInsideGeofenceResult) {
    setContent("too-far-away");

    createShiftAccountLog({
      text: getGeofenceCheckFailureReason({
        action,
        shiftId,
        workplaceName,
        checkProviders: result?.checkProviders,
        isError: !isDefined(result) || isDefined(result.error),
      }),
      accountId: workerId,
      shiftId,
      meta: {
        shiftId,
      },
    });

    logEvent(APP_V2_USER_EVENTS.TOO_FAR_AWAY_PANEL, {
      stage: "clock-in",
      shiftId,
      workerId,
      workplaceName,
      location: workplaceLocation,
      facilityLocation: workplaceLocation,
      checkProviders: result?.checkProviders,
      error: result?.error,
    });
  }

  return (
    <>
      {content === "location-permissions" && (
        <LocationPermissionsContent
          onPermissionsGranted={async () => {
            setContent("fetching-geofence-status");
            await resolveGeofenceStatus();
          }}
          onSkipLocationCheck={() => {
            setContent("accept-non-instant-pay");
          }}
        />
      )}

      {content === "fetching-geofence-status" && (
        <BottomSheetLoadingContent
          title="Confirming your location"
          description="This can take up to 10 seconds"
        />
      )}

      {content === "accept-non-instant-pay" && (
        <AcceptNonInstantPayContent
          onAccept={() => {
            onSkipLocationCheck();
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      )}

      {content === "too-far-away" && (
        <TooFarAwayPopup
          workplaceName={workplaceName}
          onTryAgain={async () => {
            await resolveGeofenceStatus();
          }}
          onSkipLocationCheck={() => {
            setContent("accept-non-instant-pay");
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      )}
    </>
  );
}
